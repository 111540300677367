<template>
  <v-card color="transparent" tile elevation="0">
    <v-card class="py-sm-8 py-4" color="light_gray" tile elevation="0">
      <v-row justify="center" no-gutters>
        <v-col cols="11" lg="9">
          <span class="text-sm-h5 text-body-1text-h5 font-weight-bold">{{ $t(`label.privacyPolicy`) }}</span>
        </v-col>
      </v-row>
    </v-card>

    <v-row justify="center" class="my-8">
      <v-col cols="11" lg="9">
        <v-card class="pa-6" color="transparent" tile elevation="5">
          <div class="html-container px-sm-6" v-html="htmlContent"></div>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import axios from 'axios'
export default {
  name: 'privacyPolicy',
  data: () => ({
    htmlContent: ''
  }),
  created() {
    this.loadHtml()
  },
  methods: {
    loadHtml() {
      let htmlPath = `/static/html/helps/en/privacyPolicy.html`
      axios({
        method: 'get',
        url: htmlPath
      }).then((result) => {
        this.htmlContent = result.data
      })
    }
  }
}
</script>
